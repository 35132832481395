import React from "react";

const TrushSqareIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.7538 7.62122C17.5193 7.2948 14.2552 7.13159 11.0059 7.13159C9.07706 7.13159 7.14824 7.23545 5.23426 7.42833L3.24609 7.62122"
        stroke="#494949"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.60229 6.64322L8.81001 5.36724C8.95838 4.44734 9.07707 3.75 10.724 3.75H13.276C14.9229 3.75 15.0416 4.47701 15.1899 5.36724L15.3977 6.62839"
        stroke="#494949"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.6619 7.74048L18.0239 17.5329C17.92 19.0611 17.831 20.2481 15.1158 20.2481H8.86939C6.15421 20.2481 6.06518 19.0611 5.96132 17.5329L5.32333 7.74048"
        stroke="#494949"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default TrushSqareIcon;
