import { addControllerPrefix } from "../helper/addControllerPrefix";

export const controllers = {
  AUTH: "/v1/auth",
  NOTAUTH: "/v1",
  WEBSITE: "/v1/website",
  DASHBOARD: "/v1/dashboard",
  BUILDING_SVG_IMAGE: "/v1/building-svg-image",
  BUILDING_FLOOR_SVG_IMAGE: "/v1/building-floor-svg-image",
  FLAT_ADDITIONAL_INFO: "/v1/flat-additional-info",
  FLAT_ADDITIONAL_INFO_PART: "/v1/flat-additional-info-part",
  HOUSE_IMAGE: "/v1/house-image",
  BUILDING_IMAGE: "/v1/building-image",
  FLOOR_IMAGE: "/v1/floor-image",
  FLAT_SVG_IMAGE: "/v1/flat-svg-image",
  NEWS: "/v1/news",
};

// auth
export const auth = {
  SIGN_IN: "/sign-in",
  REFRESH: "/refresh",
  LOGIN: "/login",
};

export const authEndpoints: typeof auth = addControllerPrefix(
  auth,
  controllers.AUTH
);

export const authEndpointsLogin: typeof auth = addControllerPrefix(
  auth,
  controllers.NOTAUTH
);

// website
const website = {
  GET_ALL_HOUSE: "/get-all-house",
  UPLOAD_FILE: "/public/attachment",
};

export const websiteEndpoints: typeof website = addControllerPrefix(
  website,
  controllers.WEBSITE
);

// dashboard
const dashboard = {
  BLOCK_STATISTIC: "/block-statistic",
  BLOCK_FLOOR_STATISTIC: "/block-floor-statistic",
  BLOCK_FLAT_DATA: "/block-flat-data",
};

export const dashboardEndpoints: typeof dashboard = addControllerPrefix(
  dashboard,
  controllers.DASHBOARD
);

// building-svg-image
const building_svg_image = {
  BUILDING_SVG_IMAG_CREATEE: "/create",
  BUILDING_SVG_IMAGE_GET_All: "/building-svg-data",
  BUILDING_SVG_IMAGE_DELETE: "/delete",
};

export const builingSvgImageEndpoints: typeof building_svg_image =
  addControllerPrefix(building_svg_image, controllers.BUILDING_SVG_IMAGE);

// building-floor-svg-image
const building_floor_svg_image = {
  BUILDING_FLOOR_SVG_IMAG_CREATEE: "/create",
  BUILDING_FLOOR_SVG_IMAGE_GET_All: "/get-by",
  BUILDING_FLOOR_SVG_IMAGE_DELETE: "/delete",
};

export const builingFloorSvgImageEndpoints: typeof building_floor_svg_image =
  addControllerPrefix(
    building_floor_svg_image,
    controllers.BUILDING_FLOOR_SVG_IMAGE
  );

// flat-additional-info
const flat_additional_info = {
  FLAT_ADDITIONAL_INFO_CREATE: "/create",
  FLAT_ADDITIONAL_INFO_ALL: "/all-additional-info",
  FLAT_ADDITIONAL_INFO_EDIT: "/edit",
};

export const flatAdditonalInfoEndpoints: typeof flat_additional_info =
  addControllerPrefix(flat_additional_info, controllers.FLAT_ADDITIONAL_INFO);

// flat-additional-info
const house_image = {
  HOUSE_IMAGE_CREATE: "/create",
};

export const houseImageEndpoints: typeof house_image = addControllerPrefix(
  house_image,
  controllers.HOUSE_IMAGE
);

// flat-additional-info-part
const flat_additional_info_part = {
  FLAT_ADDITIONAL_INFO_PART_EDIT: "/edit",
  FLAT_ADDITIONAL_INFO_PART_ALL: "/flat-all-additional-info",
  FLAT_ADDITIONAL_INFO_PART_DELETE: "/delete",
};

export const flatAdditionalInfoPartEndpoints: typeof flat_additional_info_part =
  addControllerPrefix(
    flat_additional_info_part,
    controllers.FLAT_ADDITIONAL_INFO_PART
  );

// building-image
const BuildingImage = {
  BUILDING_IMAGE_CREATE: "/create",
  BUILDING_IMAGE_GET_BY: "/get-by",
  BUILDING_IMAGE_DELETE: "/delete",
};

export const buildingImageEndpoints: typeof BuildingImage = addControllerPrefix(
  BuildingImage,
  controllers.BUILDING_IMAGE
);

// floor-image
const floor_image = {
  FLOOR_IMAGE_CREATE: "/create",
  FLOOR_IMAGE_GET_BY: "/get-by-building-id",
  FLOOR_IMAGE_DELETE: "/delete",
};

export const floorImageEndpoints: typeof floor_image = addControllerPrefix(
  floor_image,
  controllers.FLOOR_IMAGE
);

// flat-svg-image
const flat_svg_image = {
  FLAT_SVG_CREATE: "/create",
  FLAT_SVG_GET_BY: "/get-by",
  FLAT_SVG_DELETE: "/delete",
};

export const flatSVGImageEndpoints: typeof flat_svg_image = addControllerPrefix(
  flat_svg_image,
  controllers.FLAT_SVG_IMAGE
);

// news
const news = {
  NEWS_BY_ID: "/news-by-id",
  NEWS_EDIT: "/edit",
};

export const newsEndpoints: typeof news = addControllerPrefix(
  news,
  controllers.NEWS
);
