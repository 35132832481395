import React from "react";

export default function RefreshIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.3333 10.0001C18.3333 14.6001 14.6 18.3334 9.99999 18.3334C5.39999 18.3334 2.59166 13.7001 2.59166 13.7001M2.59166 13.7001H6.35832M2.59166 13.7001V17.8667M1.66666 10.0001C1.66666 5.40008 5.36666 1.66675 9.99999 1.66675C15.5583 1.66675 18.3333 6.30008 18.3333 6.30008M18.3333 6.30008V2.13341M18.3333 6.30008H14.6333"
        stroke="#5E5E5E"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
