import React from "react";

const CloseModalIcon = () => {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.2812 3.71875L3.71875 13.2812"
        stroke="#6B7280"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.2812 13.2812L3.71875 3.71875"
        stroke="#6B7280"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CloseModalIcon;
