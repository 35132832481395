interface IProps {
  setGrabbing: React.Dispatch<React.SetStateAction<boolean>>;
  setPosition: React.Dispatch<
    React.SetStateAction<{
      startY: number;
      startX: number;
      scrollLeft: number;
      scrollTop: number;
      isDown: boolean;
    }>
  >;
  grabbing: boolean;
  position: {
    startY: number;
    startX: number;
    scrollLeft: number;
    scrollTop: number;
    isDown: boolean;
  };
  bodyRef: React.MutableRefObject<any>;
}

export const grabbingScrollMove = (
  e: React.MouseEvent<HTMLDivElement>,
  props: IProps
) => {
  e.stopPropagation();

  if (props.grabbing && props.position.isDown && props.bodyRef.current) {
    const y = e.pageY - props.bodyRef.current.offsetTop;
    const walkY = y - props.position.startY;
    props.bodyRef.current.scrollTop = props.position.scrollTop - walkY;

    const x = e.pageX - props.bodyRef.current.offsetLeft;
    const walkX = x - props.position.startX;
    props.bodyRef.current.scrollLeft = props.position.scrollLeft - walkX;
  }
};

export const grabbingScrollUp = (
  e: React.MouseEvent<HTMLDivElement>,
  props: IProps
) => {
  e.stopPropagation();
  // e.preventDefault();
  if (e.nativeEvent.button === 1) {
    props.setGrabbing(false);
    document.body.style.cursor = "default";
    props.setPosition((prev) => ({
      ...prev,
      isDown: false,
    }));
  } else if (props.grabbing) {
    document.body.style.cursor = "grab";
    props.setPosition((prev) => ({
      ...prev,
      isDown: false,
    }));
  }
};

export const grabbingScrollDown = (
  e: React.MouseEvent<HTMLDivElement>,
  props: IProps
) => {
  e.stopPropagation();

  if (e.nativeEvent.button === 1) {
    e.preventDefault();
    props.setGrabbing(true);
    document.body.style.cursor = "grabbing";
    props.setPosition({
      isDown: true,
      startY: e.pageY - props.bodyRef.current.offsetTop,
      startX: e.pageX - props.bodyRef.current.offsetLeft,
      scrollTop: props.bodyRef.current.scrollTop,
      scrollLeft: props.bodyRef.current.scrollLeft,
    });
  }
  if (props.bodyRef.current) {
    if (props.grabbing) {
      document.body.style.cursor = "grabbing";
      props.setPosition({
        isDown: true,
        startY: e.pageY - props.bodyRef.current.offsetTop,
        startX: e.pageX - props.bodyRef.current.offsetLeft,
        scrollTop: props.bodyRef.current.scrollTop,
        scrollLeft: props.bodyRef.current.scrollLeft,
      });
    }
  }
};

export const grabbingScrollLeave = (
  e: React.MouseEvent<HTMLDivElement>,
  props: IProps
) => {
  e.stopPropagation();
  if (e.nativeEvent.button === 1) {
    e.preventDefault();
    props.setGrabbing(false);
    document.body.style.cursor = "default";
  } else if (props.grabbing) {
    document.body.style.cursor = "grab";
    props.setPosition((prev) => ({
      ...prev,
      isDown: false,
    }));
  }
};
