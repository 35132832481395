import { Button, Image, Space, Spin } from "antd";
import { useParams } from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";
import Dropzone from "react-dropzone";
import UploadIcon from "../../Assets/Icons/UploadIcon";
import BuildingBlockFloorFlatModal from "../../Components/BuildingId/BuildingBlockFloorFlatModal";
import BuildingBlockFloorSVGModal from "../../Components/BuildingId/BuildingBlockFloorSVGModal";
import BuildingBlockSVGModal from "../../Components/BuildingId/BuildingBlockSVGModal";
import BuildingBlockUploadImage from "../../Components/BuildingId/BuildingBlockUploadImage";
import BuildingIdCol from "../../Components/BuildingId/BuildingIdCol";
import FlatInfoModal from "../../Components/BuildingId/FlatInfoModal";
import { useBlockStatistic } from "../../queries/queries";
import styles from "./buildingId.module.scss";
import {
  useHouseImageCreate,
  useSendBlockSVG,
  useUploadFile,
} from "../../queries/mutation";
import { FileObject } from "../../utils/models/File";
import { BASE_URL } from "../../Service/RequestService";
import RefreshIcon from "../../Assets/Icons/RefreshIcon";
import TrushSqareIcon from "../../Assets/Icons/TrushSqareIcon";
import BuildingFloorUploadImageModal from "../../Components/BuildingId/BuildingFloorUploadImageModal";
import PathSquareIcon from "../../Assets/Icons/PathSquareIcon";
import { useRecoilState } from "recoil";
import { openEditor, openEditorPoints } from "../../atoms";
import OpenEditor from "../../Components/open-editor/OpenEditor";

const BuildingId = () => {
  const params = useParams();
  const uploadFile = useUploadFile(getFile);
  const houseImageCreate = useHouseImageCreate(Number(params?.id));
  const sendBuildingSVG = useSendBlockSVG();
  const [editor, setEditor] = useRecoilState(openEditor);
  const [editorPoints, setEditorPoints] = useRecoilState(openEditorPoints);
  const [svgModalVisible, setSvgModalVisible] = useState({
    visible: false,
    name: "",
    blockId: 0,
  });
  const [uploadFileBlob, setUploadFileBlob] = useState<string>("");
  const { data, isLoading, isFetching } = useBlockStatistic(Number(params?.id));

  function getFile(file: FileObject[]) {
    setUploadFileBlob(
      data?.houseImageResponse.houseImagePath
        ? data?.houseImageResponse.houseImagePath
        : file[0].absolutePath
    );
    houseImageCreate.mutate({
      houseId: Number(params?.id),
      imageId: file[0].id,
      id: data?.houseImageResponse.id ? data.houseImageResponse.id : 0,
    });
  }

  const scrollX = useRef<any>(null);

  const handleDrop = (acceptedFiles: any) => {
    const formData = new FormData();
    formData.append("files", acceptedFiles[0]);
    uploadFile.mutate(formData);
  };

  const onWheel = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    window.scroll(0, 0);
    scrollX.current.scrollLeft += e.deltaY;
  };

  useEffect(() => {
    if (data?.houseImageResponse.houseImagePath) {
      setUploadFileBlob(data.houseImageResponse.houseImagePath);
    }
  }, [data]);

  const handleOpenEditor = async () => {
    await data?.visualViewBlockStatisticWithImages.forEach((item) => {
      if (editorPoints.some((point) => point.id === item.blockId)) {
        console.log(editorPoints);
      } else {
        sendBuildingSVG.mutateAsync(item.blockId).then((res) => {
          if (res[0]) {
            setEditorPoints((prev) => [
              ...prev,
              {
                points: res[0]?.frontImage.split(" "),
                id: item.blockId,
                name: item.blockName,
                backPoints: res[0]?.backgroundImage,
                svgId: res[0]?.id,
              },
            ]);
          }
        });
      }
    });
    setEditor({
      ...editor,
      visible: true,
      image: BASE_URL + uploadFileBlob,
      type: "BLOCK",
    });
  };

  return (
    <div className={styles.buildingId}>
      <h1>{params?.name}</h1>
      <p>Bloklar umumiy ko’rinishi</p>
      <div className={styles.buildingId_uploadImages}>
        <Spin spinning={isLoading || isFetching || uploadFile.isLoading}>
          {data?.houseImageResponse.houseImagePath ? (
            <Space>
              <Image src={BASE_URL + uploadFileBlob} height={202} />
              <div className={styles.buildingId_uploadImages_right}>
                <Button className="customMiniButton">
                  <label htmlFor="file" style={{ width: "100%" }}>
                    <RefreshIcon />
                  </label>
                  <input
                    type="file"
                    id="file"
                    accept="image/*"
                    style={{ display: "none" }}
                    onChange={(e) => {
                      handleDrop(e.target.files);
                    }}
                  />
                </Button>
                <Button className="customMiniButton">
                  <TrushSqareIcon />
                </Button>
                <Button className="customMiniButton" onClick={handleOpenEditor}>
                  <PathSquareIcon />
                </Button>
              </div>
            </Space>
          ) : (
            <>
              <Dropzone
                onDrop={handleDrop}
                accept={{
                  "image/*": [".jpeg", ".png", ".PNG"],
                }}
                minSize={1024}
                maxSize={30720000}
                multiple={false}
              >
                {({
                  getRootProps,
                  getInputProps,
                  isDragActive,
                  isDragAccept,
                  isDragReject,
                }) => {
                  const additionalClass = isDragAccept
                    ? styles.buildingId_fullImageUpload_accept
                    : isDragReject
                    ? styles.buildingId_fullImageUpload_reject
                    : "";

                  return (
                    <div
                      {...getRootProps({
                        className: `${styles.buildingId_fullImageUpload} ${additionalClass}`,
                      })}
                    >
                      <input {...getInputProps()} />
                      <UploadIcon />
                      <h1>
                        Fayli oling va shuyerga tashlang yoki
                        <span>Tanlang</span>
                      </h1>
                      <p>Fayl formatlari: JPEG, JPG</p>
                    </div>
                  );
                }}
              </Dropzone>
            </>
          )}
        </Spin>
      </div>
      <div className={styles.buildingId_body}>
        <h1>Turar-joy majmuasi binolari</h1>
        <div
          className={`${styles.buildingId_body_container}`}
          ref={scrollX}
          onWheel={onWheel}
        >
          {data?.visualViewBlockStatisticWithImages.map((block) => (
            <BuildingIdCol
              key={block.blockId}
              setSvgModalVisible={setSvgModalVisible}
              name={block.blockName ? block.blockName : "block"}
              floor={block.floor}
              blockId={block.blockId}
            />
          ))}
        </div>
      </div>
      <BuildingBlockSVGModal
        name={svgModalVisible.name}
        visible={svgModalVisible.visible}
        setSvgModalVisible={setSvgModalVisible}
        blockId={svgModalVisible.blockId}
      />
      <BuildingBlockUploadImage />
      <BuildingBlockFloorFlatModal />
      <FlatInfoModal />
      <BuildingBlockFloorSVGModal />
      <BuildingFloorUploadImageModal />
      <OpenEditor />
    </div>
  );
};

export default BuildingId;
