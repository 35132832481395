import { Button, Input, Modal, Select, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import CloseIcon from "../../Assets/Icons/CloseIcon";
import TrashIcon from "../../Assets/Icons/TrashIcon";
import { flatInfoModal } from "../../atoms";
import {
  useFlatAdditionalPartDelete,
  useFlatAdditionalPartEdit,
} from "../../queries/mutation";
import {
  useFlatAdditionalInfoAll,
  useFlatAdditionalInfoPartAll,
} from "../../queries/queries";

const FlatInfoModal = () => {
  const [flatsAdditionalInfoValue, setFlatsAdditionalInfoValue] = useState<any>(
    []
  );
  const [flatInfo, setFlatInfo] = useRecoilState(flatInfoModal);
  const flatAdditionalPartDelete = useFlatAdditionalPartDelete(
    flatInfo.blockId,
    flatInfo.floorId,
    flatInfo.id
  );
  const flatAdditionalEdit = useFlatAdditionalPartEdit(
    flatInfo.blockId,
    flatInfo.floorId,
    flatInfo.id
  );
  const { data } = useFlatAdditionalInfoAll();
  const { data: flatAdditionalInfoPartAll } = useFlatAdditionalInfoPartAll(
    flatInfo.id
  );

  const handleClose = () => {
    setFlatsAdditionalInfoValue([]);
    setFlatInfo({ visible: false, id: 0, floorId: 0, blockId: 0 });
  };

  const handleFinish = () => {
    flatAdditionalEdit.mutate({
      flatId: flatInfo.id,
      flatAdditionalInfoPartList: flatsAdditionalInfoValue.map((item: any) => {
        return {
          id: item.partId ? item.partId : 0,
          flatAdditionalInfoId: item.id,
          value: item.value,
        };
      }),
    });
  };

  useEffect(() => {
    setFlatsAdditionalInfoValue(
      flatAdditionalInfoPartAll?.map((item) => {
        return {
          partId: item?.additionalInfoPartId,
          value: item?.value,
          name: item?.additionalInfoName,
        };
      })
    );
  }, [flatAdditionalEdit.isSuccess, flatAdditionalInfoPartAll]);
  return (
    <Modal
      title={
        <>
          Xonadon ma’lumotlari{" "}
          <span onClick={handleClose}>
            <CloseIcon />
          </span>
        </>
      }
      wrapClassName="building_block_svg_modal"
      visible={flatInfo.visible}
      closable={false}
      onCancel={handleClose}
      footer={false}
      width={550}
    >
      <Select
        style={{ width: "100%" }}
        showSearch
        onChange={(value: any) => {
          setFlatsAdditionalInfoValue((prevState: any) => [
            ...prevState,
            JSON.parse(value),
          ]);
        }}
        loading={false}
        className="customSelect"
        filterOption={false}
        defaultActiveFirstOption={false}
        showArrow={false}
        // notFoundContent={clientsLoading ? <Spin size="small" /> : null}
      >
        {data?.map((item, index) => (
          <Select.Option
            key={item.id}
            value={JSON.stringify({
              id: item.id,
              name: item.name,
              value: "",
            })}
          >
            {item.name}
          </Select.Option>
        ))}
      </Select>
      {/* <Input
          placeholder="Xonadonlar qidirish va qo‘shish"
          className="customInput"
        />
      </AutoComplete> */}
      <Spin spinning={flatAdditionalPartDelete.isLoading}>
        <table className="building_block_svg_modal_table">
          <tr>
            <th>Xonalar nomlanishi</th>
            <th>O‘lchami</th>
            <th>Action</th>
          </tr>
          {flatsAdditionalInfoValue?.map((item: any, index: any) => (
            <tr key={item.id}>
              <td>{item.name}</td>
              <td>
                <Input
                  type={"number"}
                  value={item.value}
                  style={{ width: 88 }}
                  className="customInput"
                  onChange={(e: any) => {
                    item.value = e.target.value;
                    setFlatsAdditionalInfoValue([...flatsAdditionalInfoValue]);
                  }}
                />
                <span>
                  m<sup>2</sup>
                </span>
              </td>
              <td>
                <b
                  onClick={() => {
                    if (item.partId) {
                      flatAdditionalPartDelete.mutate({
                        id: item.partId,
                      });
                    } else {
                      setFlatsAdditionalInfoValue(
                        flatsAdditionalInfoValue.filter(
                          (i: any, index2: any) => i !== item
                        )
                      );
                    }
                  }}
                >
                  <TrashIcon />
                </b>
              </td>
            </tr>
          ))}
        </table>
      </Spin>
      <div
        className="building_block_svg_modal_footer"
        style={{ justifyContent: "center" }}
      >
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleFinish}>Save</Button>
      </div>
    </Modal>
  );
};

export default FlatInfoModal;
