import { Link, NavLink } from "react-router-dom";
import LogOutIcon from "../../Assets/Icons/LogOutIcon";
import SettingsIcon from "../../Assets/Icons/SettingsIcon";
import { rootPaths } from "../../Routers/rootPaths";
import "./Menu.scss";

interface MenuProps {
  links: {
    path: string;
    name: string;
    icon: JSX.Element;
  }[];
}

export default function Menu({ links }: MenuProps) {
  return (
    <div className="menu">
      <div className="menu_title">
        <h1>Dashboard</h1>
        {links.map((item) => {
          return (
            <NavLink key={item.path} to={item.path} className="menu_link">
              {item.icon}
              <span>{item.name}</span>
            </NavLink>
          );
        })}
      </div>
      <div className="menu_logout">
        <Link to={rootPaths.INDEX}>
          <LogOutIcon />
          <span>Logout</span>
        </Link>
        <Link to={rootPaths.INDEX}>
          <SettingsIcon />
          <span>Settings</span>
        </Link>
      </div>
    </div>
  );
}
