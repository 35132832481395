export const colors = [
  {
    color: "#F900EA",
  },
  {
    color: "#F42C2C",
  },
  {
    color: "#2C2DA0",
  },
  {
    color: "#667684",
  },
  {
    color: "#FFA12F",
  },
  {
    color: "#0AB4FF",
  },
  {
    color: "#7B68EE",
  },
  {
    color: "#1DB954",
  },
  {
    color: "#FF5722",
  },
];
