import { Button, Form, Input, Modal } from "antd";
import { useEffect } from "react";
import { useRecoilState } from "recoil";
import CloseIcon from "../../Assets/Icons/CloseIcon";
import { buildingBlockFloorModal } from "../../atoms";
import {
  useBuildingFloorSvgImageCreate,
  useFlatSVGCreate,
} from "../../queries/mutation";
import {
  useBuildingFloorSvgImageGetBy,
  useFlatSVGGetBy,
} from "../../queries/queries";

const BuildingBlockFloorSVGModal = () => {
  const [form] = Form.useForm();
  const [svgBuildingFloorModal, setSvgBuildingFloorModal] = useRecoilState(
    buildingBlockFloorModal
  );
  const { data: BuildingFloorSVGData } = useBuildingFloorSvgImageGetBy(
    svgBuildingFloorModal.buildingId,
    svgBuildingFloorModal.floor
  );
  const { data: flatSVGData } = useFlatSVGGetBy(svgBuildingFloorModal.floor);
  const buildingFloorSVGCreate = useBuildingFloorSvgImageCreate();
  const flatSVGCreate = useFlatSVGCreate(
    svgBuildingFloorModal.buildingId,
    svgBuildingFloorModal.floor
  );
  const handleClose = () => {
    setSvgBuildingFloorModal({
      visible: false,
      floor: 0,
      name: "",
      type: "",
      buildingId: 0,
    });
    form.resetFields();
  };

  const onFinish = (values: any) => {
    if (svgBuildingFloorModal.type === "flat") {
      flatSVGCreate.mutate({
        flatId: svgBuildingFloorModal?.floor,
        id: flatSVGData ? flatSVGData.id : 0,
        imagePoint: values.frontImage,
      });
    } else {
      buildingFloorSVGCreate.mutate({
        buildingId: svgBuildingFloorModal?.buildingId,
        floor: svgBuildingFloorModal?.floor,
        floorPoint: values?.frontImage,
        id: BuildingFloorSVGData?.length ? BuildingFloorSVGData[0]?.id : 0,
      });
    }
  };

  useEffect(() => {
    if (svgBuildingFloorModal.type === "flat") {
      if (flatSVGData) {
        form.setFieldsValue({
          frontImage: flatSVGData?.imagePoint,
        });
      }
    } else {
      if (BuildingFloorSVGData) {
        form.setFieldsValue({
          frontImage: BuildingFloorSVGData[0]?.floorPoint,
        });
      }
    }
    //eslint-disable-next-line
  }, [BuildingFloorSVGData, flatSVGData]);

  return (
    <Modal
      title={
        <>
          {svgBuildingFloorModal.name}{" "}
          <span onClick={handleClose}>
            <CloseIcon />
          </span>
        </>
      }
      visible={svgBuildingFloorModal.visible}
      onCancel={handleClose}
      closable={false}
      footer={false}
      wrapClassName="building_block_svg_modal"
      width={650}
    >
      <Form layout="vertical" onFinish={onFinish} form={form}>
        <Form.Item label="SVG (old qismi)" name={"frontImage"}>
          <Input.TextArea
            className="customInput"
            autoSize={{ minRows: 10, maxRows: 10 }}
          />
        </Form.Item>
        <div
          className="building_block_svg_modal_footer"
          style={{ justifyContent: "center" }}
        >
          <Button onClick={handleClose}>Cancel</Button>
          <Button htmlType="submit" loading={buildingFloorSVGCreate.isLoading}>
            Save
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default BuildingBlockFloorSVGModal;
