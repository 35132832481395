export const coordinateSVG = (e: any, id: string) => {
  const SVGeditor: any = document.getElementById(id);
  let p = SVGeditor.createSVGPoint();
  let ctm = SVGeditor.getScreenCTM().inverse();
  p.x = e.clientX;
  p.y = e.clientY;
  p = p.matrixTransform(ctm);
  return {
    x: p.x,
    y: p.y,
  };
};
