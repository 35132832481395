import React from "react";
import { BrowserRouter } from "react-router-dom";
import "antd/dist/antd.variable.min.css";
import "./App.scss";
import RootRouter from "./Routers/RootRouter";
import { ConfigProvider } from "antd";
import { QueryClient, QueryClientProvider } from "react-query";
import { RecoilRoot } from "recoil";

const queryClient = new QueryClient();
function App() {
  ConfigProvider.config({
    theme: {
      primaryColor: "#04C35C",
    },
  });
  return (
    <RecoilRoot>
      <QueryClientProvider client={queryClient} contextSharing={true}>
        <BrowserRouter>
          <RootRouter />
        </BrowserRouter>
      </QueryClientProvider>
    </RecoilRoot>
  );
}

export default App;
