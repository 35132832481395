import { Col, Row } from "antd";
import React from "react";
import { Route, Routes } from "react-router-dom";
import { developerlink } from "../../Components/Menu/developerLink";
import Menu from "../../Components/Menu/Menu";
import developerRoutes from "./developerRoutes";

const DeveloperRouter = () => {
  return (
    <div className="main">
      <Row>
        <Col span={5}>
          <Menu links={developerlink} />
        </Col>
        <Col className="main_page" span={19}>
          <Routes>
            {developerRoutes.map((route) => (
              <Route
                element={<route.component />}
                path={route.exact ? route.path : `${route.path}/*`}
                key={route.path}
              />
            ))}
          </Routes>
        </Col>
      </Row>
    </div>
  );
};

export default DeveloperRouter;
