import { useQuery, useQueryClient } from "react-query";
import { $api } from "../Service/RequestService";
import {
  buildingImageEndpoints,
  builingFloorSvgImageEndpoints,
  builingSvgImageEndpoints,
  dashboardEndpoints,
  flatAdditionalInfoPartEndpoints,
  flatAdditonalInfoEndpoints,
  flatSVGImageEndpoints,
  floorImageEndpoints,
  newsEndpoints,
  websiteEndpoints,
} from "../utils/constants/apis";
import { queryNames } from "../utils/constants/queryNames";
import { BlockFlatData } from "../utils/models/BlockFlatData";
import { BlockFloorStatistic } from "../utils/models/BlockFloorStatistic";
import { BlockStatistic } from "../utils/models/BlockStatistic";
import { BuildingFloorSVGCreate } from "../utils/models/BuildingFloorSVGCreate";
import { BuildingImageGetBy } from "../utils/models/BuildingImageGetBy";
import { BuildingSVGCreate } from "../utils/models/BuildingSVGCreate";
import { ImageResponseGetById } from "../utils/models/File";
import { FlatAdditionalInfo } from "../utils/models/FlatAdditionalInfo";
import { FlatAdditionalPartAll } from "../utils/models/FlatAdditionalPartAll";
import { FlatSVGGetBy } from "../utils/models/FlatSVGGetBy";
import { FloorImageGetBy } from "../utils/models/FloorImageGetBy";
import { NewsId } from "../utils/models/NewsId";
import { Pagination } from "../utils/models/Pagination";
import { WebsiteGetAll } from "../utils/models/WebsiteGetAll";
import { baseErrorHandler } from "./baseErrorHandler";

export function useWebsiteGetAll() {
  return useQuery<Pagination<WebsiteGetAll>>(
    [queryNames.GET_ALL_HOUSE],
    async () => {
      const res = await $api.get(
        websiteEndpoints.GET_ALL_HOUSE + `?page=1&size=100`
      );
      return res.data.data;
    }
  );
}

export function useBlockStatistic(id?: number | null) {
  return useQuery<BlockStatistic>(
    [queryNames.BLOCK_STATISTIC, id],
    async () => {
      const res = await $api.get(dashboardEndpoints.BLOCK_STATISTIC + `/${id}`);
      return res.data.data;
    },
    {
      enabled: id !== null || id !== undefined,
    }
  );
}

export function useBlockFloorStatistic(id?: number | null) {
  return useQuery<BlockFloorStatistic[]>(
    [queryNames.BLOCK_FLOOR_STATISTIC, id],
    async () => {
      const res = await $api.get(
        dashboardEndpoints.BLOCK_FLOOR_STATISTIC + `/${id}`
      );
      return res.data.data;
    },
    {
      enabled: id !== null || id !== undefined,
    }
  );
}

export function useBlockFatData(blockId: number, id: number | null) {
  return useQuery<BlockFlatData[]>(
    [queryNames.BLOCK_FLAT_DATA, blockId, id],
    async () => {
      const res = await $api.get(
        dashboardEndpoints.BLOCK_FLAT_DATA + `/${blockId}?floor=${id}`
      );
      return res.data.data;
    },
    {
      enabled: id !== null,
    }
  );
}

export function useFile(id: number) {
  return useQuery<ImageResponseGetById>(
    [queryNames.FILE, id],
    async () => {
      const res = await $api.get(websiteEndpoints.UPLOAD_FILE + "/" + id);
      const imageBlob = await $api.get(res.data.data.absolutePath, {
        responseType: "blob",
      });
      return {
        fileObject: res.data.data,
        fileBlob: imageBlob.data,
      };
    },
    {
      enabled: !!id,
      onError: baseErrorHandler,
      refetchOnWindowFocus: false,
      refetchInterval: Infinity,
      retry: false,
    }
  );
}

export function useBuildingSvgImageGetBy(blockId: number) {
  return useQuery<BuildingSVGCreate[]>(
    [queryNames.BUILDING_SVG_IMAGE_GET_All, blockId],
    async () => {
      const res = await $api.get(
        builingSvgImageEndpoints.BUILDING_SVG_IMAGE_GET_All + `/${blockId}`
      );
      return res.data.data;
    },
    {
      enabled: !!blockId,
    }
  );
}

export function useBuildingFloorSvgImageGetBy(blockId: number, id: number) {
  return useQuery<BuildingFloorSVGCreate[]>(
    [queryNames.BUILDING_FLOOR_SVG_IMAGE_GET_All, blockId, id],
    async () => {
      const res = await $api.get(
        builingFloorSvgImageEndpoints.BUILDING_FLOOR_SVG_IMAGE_GET_All +
          `/${blockId}?floor=${id}`
      );
      return res.data.data;
    },
    {
      enabled: id !== null,
    }
  );
}

export function useFlatAdditionalInfoAll() {
  return useQuery<FlatAdditionalInfo[]>(
    [queryNames.FLAT_ADDITIONAL_INFO_ALL],
    async () => {
      const res = await $api.get(
        flatAdditonalInfoEndpoints.FLAT_ADDITIONAL_INFO_ALL
      );
      return res.data.data;
    }
  );
}

export function useBuildingImageGetBy(buildingId: number, floor: number) {
  return useQuery<BuildingImageGetBy[]>(
    [queryNames.BUILDING_IMAGE_GET_BY, buildingId],
    async () => {
      const res = await $api.get(
        buildingImageEndpoints.BUILDING_IMAGE_GET_BY +
          `/${buildingId}?floor=${floor}`
      );
      return res.data.data;
    },
    {
      enabled: floor !== null,
    }
  );
}

export function useFloorImageGetBy(buildingId: number) {
  return useQuery<FloorImageGetBy>(
    [queryNames.FLOOR_IMAGE_GET_BY, buildingId],
    async () => {
      const res = await $api.get(
        floorImageEndpoints.FLOOR_IMAGE_GET_BY + `/${buildingId}`
      );
      return res.data.data;
    },
    {
      enabled: !!buildingId,
    }
  );
}

export function useFlatSVGGetBy(flatId: number) {
  return useQuery<FlatSVGGetBy>(
    [queryNames.FLAT_SVG_GET_BY, flatId],
    async () => {
      const res = await $api.get(
        flatSVGImageEndpoints.FLAT_SVG_GET_BY + `/${flatId}`
      );
      return res.data.data;
    },
    {
      enabled: !!flatId,
    }
  );
}

export function useFlatAdditionalInfoPartAll(flatId: number) {
  return useQuery<FlatAdditionalPartAll[]>(
    [queryNames.FLAT_ADDITIONAL_INFO_PART_ALL, flatId],
    async () => {
      const res = await $api.get(
        flatAdditionalInfoPartEndpoints.FLAT_ADDITIONAL_INFO_PART_ALL +
          `/${flatId}`
      );
      return res.data.data;
    },
    {
      enabled: !!flatId,
    }
  );
}
export function useAllDeletePosts() {
  return useQuery<any>([queryNames.ALL_DELETE_POST], async () => {
    const res = await $api.get(
      `/v1/news/all-news?page=1&size=100&deleted=true`
    );
    return res.data;
  });
}

export function useAllPosts(page: any) {
  const queryClient = useQueryClient();
  return useQuery<any>(
    [queryNames.ALL_POSTS],
    async () => {
      const res = await $api.get(
        `/v1/news/all-news?page=${page}&size=10&deleted=false`
      );
      return res.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([queryNames.ALL_DELETE_POST]);
      },
    }
  );
}

export function useNewsById(id: number) {
  return useQuery<NewsId>(
    [queryNames.NEWS_BY_ID, id],
    async () => {
      const res = await $api.get(newsEndpoints.NEWS_BY_ID + `/${id}`);
      return res.data.data;
    },
    {
      enabled: !!id,
    }
  );
}
