import GalleryIcon from "../../Assets/Icons/GalleryIcon";
import { MainPaths } from "../../Routers/main/mainPaths";
import { rootPaths } from "../../Routers/rootPaths";

export const mainLink = [
  {
    path: rootPaths.MAIN + MainPaths.INDEX,
    name: "Yangiliklar",
    icon: <GalleryIcon color="#04C35C" />,
  },
];
