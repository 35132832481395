import { atom } from "recoil";
import { EditorType } from "../utils/models/editorType";
import { OpenEditorType } from "../utils/models/OpenEditorType";
import { atomPaths } from "../utils/paths/atomPaths";

export const buildingBlockFloorModal = atom({
  key: atomPaths.BUILDING_BLOCK_FLOOR_MODAL,
  default: {
    visible: false,
    floor: 0,
    name: "",
    type: "",
    buildingId: 0,
  },
});

export const buildingBlockImportImageModal = atom({
  key: atomPaths.BUILDING_BLOCK_IMPORT_IMAGE_MODAL,
  default: {
    visible: false,
    floor: 0,
    name: "",
    type: "",
    buildingId: 0,
  },
});

export const buildingFloorImportImageModal = atom({
  key: "buildingFloorImportImageModal",
  default: {
    visible: false,
    floor: 0,
    name: "",
    type: "",
    buildingId: 0,
  },
});

export const buildingFloorFlatModal = atom<{
  visible: boolean;
  blockId: number;
  id: number | null;
  name: string;
}>({
  key: atomPaths.BUILDING_FLOOR_FLAT_MODAL,
  default: {
    visible: false,
    blockId: 0,
    id: null,
    name: "",
  },
});

export const flatInfoModal = atom<{
  visible: boolean;
  id: number;
  floorId: number | null;
  blockId: number;
}>({
  key: atomPaths.FLAT_INFO_MODAL,
  default: {
    visible: false,
    id: 0,
    floorId: 0,
    blockId: 0,
  },
});

export const webSiteRole = atom({
  key: atomPaths.WEBSITE_ROLE,
  default: "",
});

export const postIdState = atom({
  key: atomPaths.POST_ID_STATE,
  default: {
    title: "",
    body: "",
    language: "",
    tags: "",
    newsId: 0,
  },
});

export const openEditor = atom<OpenEditorType>({
  key: atomPaths.OPEN_EDITOR,
  default: {
    visible: false,
    image: "",
    naturalWidth: 0,
    naturalHeight: 0,
    size: 1,
    color: "#0AB4FF",
    opacity: 70,
  },
});

export const openEditorPoints = atom<EditorType[]>({
  key: atomPaths.OPEN_EDITOR_POINTS,
  default: [],
});
