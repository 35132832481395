import React from "react";

export default function CalendarIcon() {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.5 2H9V1.5C9 1.225 8.775 1 8.5 1C8.225 1 8 1.225 8 1.5V2H4V1.5C4 1.225 3.775 1 3.5 1C3.225 1 3 1.225 3 1.5V2H2.5C1.945 2 1.505 2.45 1.505 3L1.5 10C1.5 10.55 1.945 11 2.5 11H9.5C10.05 11 10.5 10.55 10.5 10V3C10.5 2.45 10.05 2 9.5 2ZM9.5 9.5C9.5 9.775 9.275 10 9 10H3C2.725 10 2.5 9.775 2.5 9.5V4.5H9.5V9.5ZM3.5 5.5H4.5V6.5H3.5V5.5ZM5.5 5.5H6.5V6.5H5.5V5.5ZM7.5 5.5H8.5V6.5H7.5V5.5Z"
        fill="#5541D7"
      />
    </svg>
  );
}
