import React, { FC } from "react";
import { useRecoilState, useSetRecoilState } from "recoil";
import GalleryIcon from "../../Assets/Icons/GalleryIcon";
import PathSquareIcon from "../../Assets/Icons/PathSquareIcon";
import TickCircleIcon from "../../Assets/Icons/TickCircleIcon";
import {
  buildingBlockFloorModal,
  buildingFloorFlatModal,
  buildingFloorImportImageModal,
  openEditor,
  openEditorPoints,
} from "../../atoms";
import styles from "../../pages/BuildingId/buildingId.module.scss";
import {
  useSendBlockFloorFlats,
  useSendBlockFloorFlatsSVG,
  useSendBlockFloorImage,
} from "../../queries/mutation";
import { BASE_URL } from "../../Service/RequestService";
import { BlockFloorStatistic } from "../../utils/models/BlockFloorStatistic";

interface BuildingIdColProps {
  floor: number;
  id: number;
  blockId: number;
  data: BlockFloorStatistic;
}

const BuildingIdRow: FC<BuildingIdColProps> = ({
  floor,
  blockId,
  id,
  data,
}) => {
  const flats = useSendBlockFloorFlats();
  const flatsSVG = useSendBlockFloorFlatsSVG();
  const sendBlockImage = useSendBlockFloorImage();
  const setUploadImageModal = useSetRecoilState(buildingFloorImportImageModal);
  const setSvgBuildingFloorModal = useSetRecoilState(buildingBlockFloorModal);
  const setFloorflatsModal = useSetRecoilState(buildingFloorFlatModal);
  const setEditorPoints = useSetRecoilState(openEditorPoints);
  const [editor, setEditor] = useRecoilState(openEditor);

  const hundleSVG = (e: any) => {
    e.stopPropagation();
    setSvgBuildingFloorModal({
      visible: true,
      floor: floor,
      name: "1-Block " + floor + "-qavat",
      type: "floor",
      buildingId: blockId,
    });
  };

  const handleImage = (e: any) => {
    e.stopPropagation();
    setUploadImageModal({
      visible: true,
      floor: floor,
      name: "1-Block " + floor + "-qavat",
      type: "row",
      buildingId: blockId,
    });
  };

  const handleFloor = () => {
    setFloorflatsModal({
      visible: true,
      id: id,
      name: floor + "-qavat",
      blockId: blockId,
    });
  };

  const handleSVGEditor = (
    e: React.MouseEvent<HTMLSpanElement, MouseEvent>
  ) => {
    e.stopPropagation();
    flats.mutateAsync({ blockId, id }).then((res) => {
      console.log(res);
      res.forEach((item) => {
        flatsSVG.mutateAsync(item.flatId).then((res2) => {
          setEditorPoints((prev) => [
            ...prev,
            {
              points: res2?.imagePoint.split(" "),
              id: item?.flatId,
              svgId: res2?.id,
              name: item?.flatNumber,
            },
          ]);
        });
      });
    });
    sendBlockImage.mutateAsync({ blockId, id }).then((res) => {
      setEditor({
        ...editor,
        visible: true,
        image: BASE_URL + res[0]?.imagePath,
        id: blockId,
        type: "FLAT",
        addId: id,
      });
    });
  };

  return (
    <div
      className={styles.buildingId_body_container_col_container_row}
      onClick={handleFloor}
    >
      <h1>{floor}-qavat</h1>
      <div>
        <span onClick={handleImage}>
          <GalleryIcon color="#3999F2" />
        </span>
        <span onClick={(e) => handleSVGEditor(e)}>
          <PathSquareIcon stroke="#3999F2" size={24} />
        </span>
        <span onClick={hundleSVG}>SVG</span>
        <span>
          <TickCircleIcon
            color={
              data &&
              (data?.existBuildingFloorSVGImage && data?.existBuildingImage
                ? "#04C45C"
                : data?.existBuildingFloorSVGImage || data?.existBuildingImage
                ? "#FFC908"
                : "")
            }
          />
        </span>
      </div>
    </div>
  );
};

export default BuildingIdRow;
