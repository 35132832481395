import React from "react";

export default function EditIcon() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.84006 2.40006L3.36673 8.1934C3.16006 8.4134 2.96006 8.84673 2.92006 9.14673L2.6734 11.3067C2.58673 12.0867 3.14673 12.6201 3.92006 12.4867L6.06673 12.1201C6.36673 12.0667 6.78673 11.8467 6.9934 11.6201L12.4667 5.82673C13.4134 4.82673 13.8401 3.68673 12.3667 2.2934C10.9001 0.913397 9.78673 1.40006 8.84006 2.40006Z"
        stroke="#5E5E5E"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7.92676 3.3667C8.21342 5.2067 9.70676 6.61337 11.5601 6.80003"
        stroke="#5E5E5E"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M2 14.6667H14"
        stroke="#5E5E5E"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
