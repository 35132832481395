import React, { FC, useState } from "react";
import { useRecoilState, useSetRecoilState } from "recoil";
import GalleryIcon from "../../Assets/Icons/GalleryIcon";
import PathSquareIcon from "../../Assets/Icons/PathSquareIcon";
import TickCircleIcon from "../../Assets/Icons/TickCircleIcon";
import {
  buildingBlockImportImageModal,
  openEditor,
  openEditorPoints,
} from "../../atoms";
import styles from "../../pages/BuildingId/buildingId.module.scss";
import {
  useSendBlockFloorSVG,
  useSendBlockImage,
} from "../../queries/mutation";
import { useBlockFloorStatistic } from "../../queries/queries";
import { BASE_URL } from "../../Service/RequestService";
import BuildingIdRow from "./BuildingIdRow";

interface BuildingIdRowProps {
  setSvgModalVisible: React.Dispatch<
    React.SetStateAction<{
      visible: boolean;
      name: string;
      blockId: number;
    }>
  >;
  name: string;
  floor: number;
  blockId: number;
}

const BuildingIdCol: FC<BuildingIdRowProps> = ({
  setSvgModalVisible,
  name,
  floor,
  blockId,
}) => {
  const { data } = useBlockFloorStatistic(blockId);
  const sendBlockFloorSVG = useSendBlockFloorSVG();
  const sendBlockImage = useSendBlockImage();
  const setUploadImageModal = useSetRecoilState(buildingBlockImportImageModal);
  const [editor, setEditor] = useRecoilState(openEditor);
  const [editorPoints, setEditorPoints] = useRecoilState(openEditorPoints);
  const [visibleRow, setVisibleRow] = useState(false);

  const handleCol = () => {
    setVisibleRow(!visibleRow);
  };

  const openSVGModal = (e: any) => {
    e.stopPropagation();
    setSvgModalVisible({
      visible: true,
      name: "1-Block SVG Kodlari",
      blockId: blockId,
    });
  };

  const handleUploadImage = (e: any) => {
    e.stopPropagation();
    setUploadImageModal({
      visible: true,
      floor: floor,
      name: name && name + floor && `${name}-Block`,
      type: "col",
      buildingId: blockId,
    });
  };

  const onWheel = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const handleSVGEditor = (
    e: React.MouseEvent<HTMLSpanElement, MouseEvent>
  ) => {
    e.stopPropagation();
    data?.forEach((item) => {
      if (editorPoints.some((point) => point.id === item.blockId)) {
      } else {
        sendBlockFloorSVG
          .mutateAsync({ blockId: item.blockId, id: item.floorName })
          .then((res) => {
            setEditorPoints((prev) => [
              ...prev,
              {
                points: res[0].floorPoint.split(" "),
                id: res[0].floor,
                name: String(res[0].floor),
                svgId: res[0].id,
              },
            ]);
          });
      }
    });
    sendBlockImage.mutateAsync(blockId).then((res) => {
      setEditor({
        ...editor,
        visible: true,
        image: BASE_URL + res?.imagePath,
        id: blockId,
        type: "FLOOR",
      });
    });
  };
  return (
    <div className={styles.buildingId_body_container_col} onWheel={onWheel}>
      <div
        className={styles.buildingId_body_container_col_header}
        onClick={handleCol}
      >
        <h1>
          {name} {floor && <span>{floor}-qavatli</span>}
        </h1>
        <div>
          <span onClick={handleUploadImage}>
            <GalleryIcon color="#3999F2" />
          </span>
          <span onClick={(e) => handleSVGEditor(e)}>
            <PathSquareIcon stroke="#3999F2" size={24} />
          </span>
          <span onClick={openSVGModal}>SVG</span>
          <span>
            <TickCircleIcon
              color={
                data &&
                (data[0]?.existBuildingFloorSVGImage &&
                data[0]?.existBuildingImage
                  ? "#04C45C"
                  : data[0]?.existBuildingFloorSVGImage ||
                    data[0]?.existBuildingImage
                  ? "#FFC908"
                  : "")
              }
            />
          </span>
        </div>
      </div>
      {visibleRow && (
        <div className={styles.buildingId_body_container_col_container}>
          {data?.map((floor) => (
            <BuildingIdRow
              blockId={blockId}
              id={floor.floorName}
              floor={floor.floorName}
              key={floor.blockId}
              data={floor}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default BuildingIdCol;
