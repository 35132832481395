import { showMessage } from "./showMessage";
import { message } from "antd";

export const baseErrorHandler = (err: any) => {
  if (err?.response.status === 401) {
    return console.log("Error 401");
  }
  if (err.response?.data?.message) {
    showMessage(err.response.data.message, err.response.data.accept);
  } else {
    message.error("Xatolik mavjud");
  }
};
