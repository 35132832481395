import { Button, Col, Form, Input, Row, Select } from "antd";
import React, { useState } from "react";
import style from "./News.module.scss";
import type { UploadProps } from "antd";
import { langs } from "../../utils/paths/langPath";
import Dropzone from "react-dropzone";
import {
  useCreatePost,
  // useSearchPost,
  useUploadFile,
} from "../../queries/mutation";
import { FileObject } from "../../utils/models/File";
import { useRecoilValue } from "recoil";
import { postIdState } from "../../atoms";
import { dataMessages } from "../../utils/paths/dataMessages";

const { Option } = Select;
const { TextArea } = Input;
// const { Dragger } = Upload;
const props: UploadProps = {
  name: "file",
  multiple: true,
  maxCount: 1,
  action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
};
export default function CreatePost() {
  const postIdValue = useRecoilValue(postIdState);
  const createPosts = useCreatePost();
  const uploadFile = useUploadFile(getFile);
  // const postData = useSearchPost();
  const [first, setfirst] = useState("");
  const [tagName, setTagName] = useState<string[]>([]);
  const [title, setTitle] = useState<string>(postIdValue.title);
  const [text, setText] = useState<string>("");
  // const [uploadFileBlob, setUploadFileBlob] = useState<FileObject>();
  function getFile(file: FileObject[]) {
    // setUploadFileBlob(file[0]);
  }
  const changeTitle = (e: any) => {
    setTitle(e.target.value);
  };
  const changeText = (e: any) => {
    setText(e.target.value);
  };
  const changeTag = (e: any) => {
    e.preventDefault();
    setfirst(e.target.value);
  };
  const pressEnterTag = (e: any) => {
    e.preventDefault();
    setTagName((arg: any) => [...arg, first]);
    setfirst("");
  };
  const handleDrop = (acceptedFiles: any) => {
    const formData = new FormData();
    formData.append("files", acceptedFiles[0]);
    uploadFile.mutate(formData);
  };
  const onFinish = (fields: any) => {
    const data = {
      ...fields,
      title: title,
      body: text,
      tags: first ? first : tagName.join(","),
      language: fields.lang,
      imageId: 1,
    };
    createPosts.mutate(data);
  };

  return (
    <div className={style.createPost}>
      <h1>Yangiliklar</h1>
      <Row>
        <Col span={12}>
          <div className={style.createPost_text}>
            <Input
              onChange={changeTitle}
              // defaultValue={postIdValue.title}
              placeholder="Your title"
            />
            <TextArea onChange={changeText} placeholder="Write a Post" />
          </div>
        </Col>
        <Col span={12}>
          <Form
            name="createPosts"
            onFinish={onFinish}
            className="createPostForm"
            layout="vertical"
          >
            <Form.Item
              rules={[{ required: true, message: dataMessages.NOTDATA }]}
              name="lang"
              label="Chop etish tili"
            >
              <Select defaultValue={"Til turini tanlang"}>
                <Option value={langs.UZ}>O'zbek tili</Option>
                <Option value={langs.RU}>Русскый язык</Option>
              </Select>
            </Form.Item>
            <Form.Item
              name="tag"
              // rules={[{ required: true, message: dataMessages.NOTDATA }]}
              label="Teglar"
              initialValue={first}
            >
              <Input
                onPressEnter={pressEnterTag}
                onChange={changeTag}
                placeholder="Teglarni kiriting"
              />
              <div className={style.createPost_tags}>
                {tagName.map((item: any) => (
                  <div style={{ opacity: tagName ? 1 : 0 }}>{item}</div>
                ))}
              </div>
            </Form.Item>
            <Form.Item
              name="img"
              // rules={[{ required: true, message: dataMessages.NOTDATA }]}
              label="Rasm"
            >
              <Dropzone
                onDrop={handleDrop}
                accept={{
                  "image/*": [".jpeg", ".png", ".PNG"],
                }}
                minSize={1024}
                maxSize={30720000}
                multiple={true}
              >
                {({ getInputProps }) => {
                  return <input {...getInputProps()} />;
                }}
              </Dropzone>
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Chop etish
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </div>
  );
}
