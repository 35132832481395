import { Modal } from "antd";
import { useRecoilState, useSetRecoilState } from "recoil";
import { openEditor, openEditorPoints } from "../../atoms";
import OpenEditorContent from "./open-editor-content/OpenEditorContent";
import OpenEditorHeader from "./open-editor-header/OpenEditorHeader";
import styles from "./openEditor.module.scss";

function OpenEditor() {
  const setEditorPoints = useSetRecoilState(openEditorPoints);
  const [editor, setEditor] = useRecoilState(openEditor);

  const handleClose = () => {
    setEditor({
      ...editor,
      visible: false,
    });
    setEditorPoints([]);
  };

  return (
    <Modal
      title={
        "Tashqi ko’rinishni belgilash " +
        (editor.type === "FLAT" ? editor.addId + "-qavat" : "")
      }
      footer={false}
      visible={editor.visible}
      onCancel={handleClose}
      wrapClassName={styles.container}
    >
      <OpenEditorHeader />
      <OpenEditorContent />
    </Modal>
  );
}

export default OpenEditor;
