import React from "react";

const CheveroRightIcon = () => {
  return (
    <svg
      width="11"
      height="12"
      viewBox="0 0 11 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.91109 4.51119C3.12588 4.2964 3.47412 4.2964 3.68891 4.51119L5.5 6.32228L7.31109 4.51119C7.52588 4.2964 7.87412 4.2964 8.08891 4.51119C8.3037 4.72598 8.3037 5.07422 8.08891 5.28901L5.88891 7.48901C5.67412 7.70379 5.32588 7.70379 5.11109 7.48901L2.91109 5.28901C2.6963 5.07422 2.6963 4.72598 2.91109 4.51119Z"
        fill="#9CA3AF"
      />
    </svg>
  );
};

export default CheveroRightIcon;
